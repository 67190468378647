import type { RouteObject } from "react-router-dom";

import { Protected } from "@gemunion/common-pages";

import { EventsList } from "./events";
import { Careers } from "./careers";
import { OurTeam } from "./company/our-team";
import { TechnicalStack } from "./company/technical-stack";
import { ArticlesList } from "./articles";
import { ContactPartnership } from "./contacts/partnership";
import { Page } from "./page";
import { Profile } from "./profile";

export const infrastructureRoutes: Array<RouteObject> = [
  {
    path: "/profile",
    element: <Protected />,
    children: [
      { index: true, element: <Profile /> },
      { path: "/profile/:tab", element: <Profile /> },
    ],
  },
  {
    path: "/contact",
    children: [
      { path: "/contact/partnership", element: <ContactPartnership /> },
      { path: "/contact/enterprise", element: <ContactPartnership /> },
    ],
  },
  {
    path: "/articles",
    element: <ArticlesList />,
  },
  {
    path: "/pages",
    children: [{ path: "/pages/:slug", element: <Page /> }],
  },
  {
    path: "/events",
    element: <EventsList />,
  },
  {
    path: "/careers",
    element: <Careers />,
  },
  {
    path: "/our-team",
    element: <OurTeam />,
  },
  {
    path: "/technical-stack",
    element: <TechnicalStack />,
  },
];
