import { FC } from "react";

import { StyledHeader } from "../../../landing/styled";
import { StyledDescription } from "../../../../components/forms/common-contact/styled";

export const ApplyForTierText: FC = () => {
  return (
    <>
      <StyledHeader>Apply for tier</StyledHeader>

      <StyledDescription>
        Step into the future of play-to-earn gaming with our robust blockchain platform. Our tier system caters to every
        ambition, from fledgling endeavors to established franchises, equipping you with the essential tools and
        technologies for success in the digital realm.
      </StyledDescription>
      <StyledDescription>
        Dive right in with our seamless application process. Apply for your chosen tier and get access to key features
        to aid your gaming venture. Begin your play-to-earn revolution with us today!
      </StyledDescription>
    </>
  );
};
