import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Grid, Hidden, useScrollTrigger } from "@mui/material";

import { EnabledLanguages } from "@framework/constants";
import { Localization } from "@gemunion/provider-localization";

import Logo from "../../../../static/files/landing/logo.svg";
import { Menu } from "./menu";
import { Sections } from "./sections";
import { StyledAppBar, StyledLink, StyledLogo, StyledToolbar } from "./styled";
import { MobileMenu } from "./mobile-menu";

export const Header: FC = () => {
  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <StyledAppBar position="fixed" elevation={scrolled ? 1 : 0}>
      <StyledToolbar scrolled={scrolled}>
        <Grid container>
          <Grid item xs={12} md={5} justifyContent="space-between" sx={{ display: "flex", alignItems: "center" }}>
            <Hidden mdDown>
              <Menu />
            </Hidden>
            <Hidden mdUp>
              <StyledLink component={RouterLink} to="/">
                <StyledLogo scrolled={scrolled} component={Logo} viewBox="0 0 449.91 95.5" />
              </StyledLink>

              <MobileMenu />
            </Hidden>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} md={3.5} justifyContent="flex-start" sx={{ display: "flex", alignItems: "center" }}>
              <StyledLink component={RouterLink} to="/">
                <StyledLogo scrolled={scrolled} component={Logo} viewBox="0 0 449.91 95.5" />
              </StyledLink>
            </Grid>
            <Grid item xs={12} md={3.5} justifyContent="flex-end" sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <Localization languages={Object.values(EnabledLanguages)} />
                <Sections />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </StyledToolbar>
    </StyledAppBar>
  );
};
