import { object, string } from "yup";

import { displayNameValidationSchema, emailValidationSchema } from "@gemunion/yup-rules";
import { EnabledLanguages } from "@framework/constants";

import { urlValidationSchema } from "../../../../components/validation";

export const validationSchema = object().shape({
  email: emailValidationSchema,
  displayName: displayNameValidationSchema,
  language: string().oneOf(Object.values(EnabledLanguages)),
  imageUrl: urlValidationSchema,
});
