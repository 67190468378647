import { FC, useEffect } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { CheckboxInput, SwitchInput } from "@gemunion/mui-inputs-core";

import { StyledFormGroup } from "./styled";

export const MechanicsMarketing: FC = () => {
  const form = useFormContext<any>();
  const moduleNative = useWatch({ name: "module.native" });
  const moduleErc20 = useWatch({ name: "module.erc20" });
  const moduleErc721 = useWatch({ name: "module.erc721" });
  const moduleErc998 = useWatch({ name: "module.erc998" });
  const moduleErc1155 = useWatch({ name: "module.erc1155" });

  useEffect(() => {
    if (!(moduleErc20 || moduleErc721 || moduleErc998 || moduleErc1155)) {
      form.setValue("mechanics.marketing.claim", false);
    }
    if (!(moduleErc20 || moduleNative)) {
      form.setValue("mechanics.marketing.vesting", false);
    }
    if (!(moduleErc20 || moduleErc721 || moduleErc998 || moduleErc1155)) {
      form.setValue("mechanics.marketing.waitList", false);
    }
    if (!(moduleErc721 || moduleErc998 || moduleErc1155)) {
      form.setValue("mechanics.marketing.mystery", false);
    }
    if (!(moduleNative || moduleErc20 || moduleErc721 || moduleErc998 || moduleErc1155)) {
      form.setValue("mechanics.marketing.staking", false);
    }
  }, [moduleNative, moduleErc20, moduleErc721, moduleErc998, moduleErc1155]);

  return (
    <Grid>
      <SwitchInput name="modules.mechanics.marketing" checked />
      <StyledFormGroup>
        <CheckboxInput
          name="mechanics.marketing.claim"
          disabled={!(moduleErc20 || moduleErc721 || moduleErc998 || moduleErc1155)}
        />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.marketing.claim" />
          <Link href="https://gemunion.github.io/admin/mechanics-marketing/claim/">more</Link>
        </Typography>
        <CheckboxInput
          name="mechanics.marketing.waitList"
          disabled={!(moduleErc20 || moduleErc721 || moduleErc998 || moduleErc1155)}
        />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.marketing.waitList" />
          <Link href="https://gemunion.github.io/admin/mechanics-marketing/wait-list/">more</Link>
        </Typography>
        <CheckboxInput name="mechanics.marketing.vesting" disabled={!(moduleNative || moduleErc20)} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.marketing.vesting" />
          <Link href="https://gemunion.github.io/admin/mechanics-marketing/vesting/">more</Link>
        </Typography>
        <CheckboxInput name="mechanics.marketing.mystery" disabled={!(moduleErc721 || moduleErc998 || moduleErc1155)} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.marketing.mystery" />
          <Link href="https://gemunion.github.io/admin/mechanics-marketing/mystery/">more</Link>
        </Typography>
        <CheckboxInput
          name="mechanics.marketing.staking"
          disabled={!(moduleNative || moduleErc20 || moduleErc721 || moduleErc998 || moduleErc1155)}
        />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.marketing.staking" />
          <Link href="https://gemunion.github.io/admin/mechanics-marketing/staking/">more</Link>
        </Typography>
      </StyledFormGroup>
    </Grid>
  );
};
