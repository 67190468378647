import type { IThemeProviderProps } from "@gemunion/provider-theme";

export const themeProps: IThemeProviderProps = {
  darkPalette: {
    background: {
      default: "#4286f5",
      paper: "#f5f5f5",
    },
    primary: {
      contrastText: "#f5f5f5",
      main: "#4286f5",
    },
    text: {
      primary: "#272a30",
    },
  },
  lightPalette: {
    background: {
      default: "#4286f5",
      paper: "#f5f5f5",
    },
    primary: {
      contrastText: "#f5f5f5",
      main: "#4286f5",
    },
    text: {
      primary: "#272a30",
    },
  },
  options: {
    typography: {
      fontFamily: ['"Montserrat"', "Roboto", '"Segoe UI"', '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background: "#F5F5F5",
            fontFamily: ['"Montserrat", Roboto'],
            fontOpticalSizing: "auto",
          },
        },
      },
    },
  },
};
