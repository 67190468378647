import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { ProductTypeSelection } from "@gemunion/license-pages";

import { Root } from "../../../../components/common/styled";
import { StyledDescription, StyledHeader } from "./styled";

export const Pricing: FC = () => {
  return (
    <Root>
      <StyledHeader>
        <FormattedMessage id="pages.pricing.title" />
      </StyledHeader>

      <StyledDescription>
        <FormattedMessage id="pages.pricing.description" />
      </StyledDescription>

      <ProductTypeSelection internal />
    </Root>
  );
};
