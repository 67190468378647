import { object, string } from "yup";

import { emailValidationSchema } from "@gemunion/yup-rules";

export const validationSchema = object().shape({
  email: emailValidationSchema,
  displayName: string().required("form.validations.valueMissing"),
  companyName: string().required("form.validations.valueMissing"),
  jobTitle: string().required("form.validations.valueMissing"),
  text: string().required("form.validations.valueMissing"),
});
