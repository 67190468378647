import { ChangeEvent, FC } from "react";
import { useFormContext } from "react-hook-form";

import { EntityInput } from "@gemunion/mui-inputs-entity";
import { TokenType } from "@gemunion/types-blockchain";
import { ContractFeatures } from "@framework/types";

export const ContractInput: FC = () => {
  const form = useFormContext<any>();

  const handleChange = (_event: ChangeEvent<unknown>, option: any): void => {
    form.setValue("contractId", option?.id ?? 0);
    form.setValue("contract.address", option?.address ?? "0x");
    form.setValue("contract.decimals", option?.decimals ?? 0);
  };

  return (
    <EntityInput
      name="contractId"
      controller="contracts"
      autoselect
      disableClear
      data={{
        contractType: [TokenType.ERC20],
        contractFeatures: [ContractFeatures.STABLE_COIN],
        chainId: 56,
      }}
      onChange={handleChange}
    />
  );
};
