import { FC, useEffect } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { CheckboxInput, SwitchInput } from "@gemunion/mui-inputs-core";

import { StyledFormGroup } from "./styled";

export const MechanicsGambling: FC = () => {
  const form = useFormContext<any>();
  const moduleNative = useWatch({ name: "module.native" });
  const moduleErc20 = useWatch({ name: "module.erc20" });
  const moduleErc721 = useWatch({ name: "module.erc721" });

  useEffect(() => {
    if (!((moduleErc20 || moduleErc721) && moduleErc721)) {
      form.setValue("mechanics.gambling.raffle", false);
    }
    if (!((moduleErc20 || moduleErc721) && moduleErc721)) {
      form.setValue("mechanics.gambling.lottery", false);
    }
    if (!(moduleNative || moduleErc20)) {
      form.setValue("mechanics.gambling.ponzi", false);
    }
  }, [moduleNative, moduleErc20, moduleErc721]);

  return (
    <Grid>
      <SwitchInput name="modules.mechanics.gambling" checked />
      <StyledFormGroup>
        <CheckboxInput name="mechanics.gambling.raffle" disabled={!((moduleNative || moduleErc20) && moduleErc721)} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.gambling.raffle" />
          <Link href="https://gemunion.github.io/admin/mechanics-gambling/raffle/">more</Link>
        </Typography>
        <CheckboxInput name="mechanics.gambling.lottery" disabled={!((moduleNative || moduleErc20) && moduleErc721)} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.gambling.lottery" />
          <Link href="https://gemunion.github.io/admin/mechanics-gambling/lottery/">more</Link>
        </Typography>
        <CheckboxInput name="mechanics.gambling.ponzi" disabled={!(moduleNative || moduleErc20)} />
        <Typography>
          <FormattedMessage id="pages.calculator.mechanics.gambling.ponzi" />
          <Link href="https://gemunion.github.io/admin/mechanics-gambling/ponzi/">more</Link>
        </Typography>
      </StyledFormGroup>
    </Grid>
  );
};
