import { FC } from "react";
import { useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { StyledTotal } from "./styled";

export const Total: FC = () => {
  const value = useWatch({});
  const [searchParams] = useSearchParams();

  let total = 0;

  if (value.module.native || value.module.erc20) {
    total += 3;
  }

  if (value.module.native && value.module.erc20) {
    total += 1;
  }

  if (value.module.erc721) {
    total += 6;
  }

  if (value.module.erc998) {
    total += 10;
    if (value.erc998.composition.erc20) {
      total += 2;
    }
    if (value.erc998.composition.erc721) {
      total += 0;
    }
    if (value.erc998.composition.erc998) {
      total += 0;
    }
    if (value.erc998.composition.erc1155) {
      total += 3;
    }
  }

  if (value.module.erc1155) {
    total += 3;
  }

  if (value.module.integrations) {
    if (value.integrations.ipfs) {
      total += 0.5;
    }
    if (value.integrations.coinGecko) {
      total += 0.5;
    }
    if (value.integrations.coinMarketCap) {
      total += 0.5;
    }
    if (value.integrations.oneInch) {
      total += 2;
    }
  }

  if (value.module.mechanics.marketing) {
    if (value.mechanics.marketing.vesting) {
      if (value.module.native) {
        total += 1;
      }
      if (value.module.erc20) {
        total += 1;
      }
    }

    if (value.mechanics.marketing.waitList) {
      if (value.module.erc20) {
        total += 1;
      }
      if (value.module.erc721) {
        total += 1;
      }
      if (value.module.erc998) {
        total += 1;
      }
      if (value.module.erc1155) {
        total += 1;
      }
    }

    if (value.mechanics.marketing.claim) {
      if (value.module.erc20) {
        total += 1;
      }
      if (value.module.erc721) {
        total += 1;
      }
      if (value.module.erc998) {
        total += 1;
      }
      if (value.module.erc1155) {
        total += 1;
      }
    }

    if (value.mechanics.marketing.mystery) {
      total += 1;
      if (value.module.erc721) {
        total += 1;
      }
      if (value.module.erc998) {
        total += 1;
      }
      if (value.module.erc1155) {
        total += 1;
      }
    }

    if (value.mechanics.marketing.staking) {
      total += 2;
      if (value.module.native) {
        total += 1;
      }
      if (value.module.erc20) {
        total += 1;
      }
      if (value.module.erc721) {
        total += 1;
      }
      if (value.module.erc998) {
        total += 1;
      }
      if (value.module.erc1155) {
        total += 1;
      }
    }
  }

  if (value.module.mechanics.gaming) {
    if (value.mechanics.gaming.grade) {
      if (value.module.erc721) {
        total += 1;
      }
      if (value.module.erc998) {
        total += 1;
      }
    }

    if (value.mechanics.gaming.craft) {
      if (value.module.erc721) {
        total += 1;
      }
      if (value.module.erc998) {
        total += 1;
      }
      if (value.module.erc1155) {
        total += 1; // should always be on
      }
    }

    if (value.mechanics.gaming.dismantle) {
      if (value.module.erc721) {
        total += 1;
      }
      if (value.module.erc998) {
        total += 1;
      }
      if (value.module.erc1155) {
        total += 1; // should always be on
      }
    }

    if (value.mechanics.gaming.merge) {
      if (value.module.erc721) {
        total += 2;
      }
      if (value.module.erc998) {
        total += 2;
      }
    }

    if (value.mechanics.gaming.rent) {
      if (value.module.erc721) {
        total += 1;
      }
      if (value.module.erc998) {
        total += 1;
      }
    }
  }

  if (value.module.mechanics.meta) {
    if (value.mechanics.meta.promo) {
      if (value.module.erc721) {
        total += 1;
      }
      if (value.module.erc998) {
        total += 1;
      }
      if (value.module.erc1155) {
        total += 1;
      }
      if (value.mechanics.marketing.mystery) {
        total += 1;
      }
    }

    if (value.mechanics.meta.referralProgram) {
      total += 2;
    }

    if (value.mechanics.meta.paymentSplitter) {
      total += 1;
    }
  }

  if (value.module.mechanics.gambling) {
    if (value.mechanics.gambling.raffle) {
      total += 2;
    }

    if (value.mechanics.gambling.lottery) {
      total += 2;
      if (value.module.native) {
        total += 1;
      }
      if (value.module.erc20) {
        total += 1;
      }
    }

    if (value.mechanics.gambling.ponzi) {
      total += 2;
      if (value.module.native) {
        total += 1;
      }
      if (value.module.erc20) {
        total += 1;
      }
    }

    if (value.mechanics.meta.achievements) {
      total += 2;
    }
  }

  if (value.module.other) {
    if (value.other.marketplace) {
      total += 0;
    }
    if (value.other.emails) {
      total += 0.5;
    }
    if (value.other.users) {
      total += 0;
    }
    if (value.other.json) {
      total += 1;
    }
    if (value.other.game) {
      total += 1;
    }
  }

  if (searchParams.get("show") === "true") {
    return <StyledTotal>USDT {(Math.max(total, 5) * 10000).toLocaleString()}</StyledTotal>;
  }

  return null;
};
