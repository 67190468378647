import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { ContactType } from "@framework/types";
import { RatePlansSelection } from "@gemunion/license-pages";

import { CommonContactForm } from "../../../../components/forms/common-contact";
import { Root } from "../../../../components/common/styled";
import { ApplyForTierText } from "./apply-for-tier";
import { StyledDescription, StyledHeader } from "./styled";

export const CalculatorSaas: FC = () => {
  return (
    <Root>
      <StyledHeader>
        <FormattedMessage id="pages.ratePlan.title" />
      </StyledHeader>

      <StyledDescription>
        <FormattedMessage id="pages.ratePlan.description" />
      </StyledDescription>

      <RatePlansSelection />

      <CommonContactForm contactType={ContactType.APPLY_FOR_TIER}>
        <ApplyForTierText />
      </CommonContactForm>
    </Root>
  );
};
