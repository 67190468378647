import { FC, useEffect } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { CheckboxInput, SwitchInput } from "@gemunion/mui-inputs-core";

import { StyledFormGroup } from "./styled";

export const Integrations: FC = () => {
  const form = useFormContext<any>();
  const moduleErc721 = useWatch({ name: "module.erc721" });
  const moduleErc998 = useWatch({ name: "module.erc998" });
  const moduleErc1155 = useWatch({ name: "module.erc1155" });

  useEffect(() => {
    if (!(moduleErc721 || moduleErc998 || moduleErc1155)) {
      form.setValue("integrations.ipfs", false);
    }
  }, [moduleErc721, moduleErc998, moduleErc1155]);

  return (
    <Grid>
      <SwitchInput name="modules.integrations" checked />
      <StyledFormGroup>
        <CheckboxInput name="integrations.ipfs" disabled={!(moduleErc721 || moduleErc998 || moduleErc1155)} />
        <Typography>
          <FormattedMessage id="pages.calculator.integrations.ipfs" />
        </Typography>
        <CheckboxInput name="integrations.coinGecko" />
        <Typography>
          <FormattedMessage id="pages.calculator.integrations.coinGecko" />
          <Link href="https://www.coingecko.com">visit</Link>
        </Typography>
        <CheckboxInput name="integrations.coinMarketCap" />
        <Typography>
          <FormattedMessage id="pages.calculator.integrations.coinMarketCap" />
          <Link href="https://coinmarketcap.com/">visit</Link>
        </Typography>
        <CheckboxInput name="integrations.oneInch" />
        <Typography>
          <FormattedMessage id="pages.calculator.integrations.oneInch" />
          <Link href="https://1inch.io/">visit</Link>
        </Typography>
      </StyledFormGroup>
    </Grid>
  );
};
