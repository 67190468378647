import { FC, useEffect } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { CheckboxInput, SwitchInput } from "@gemunion/mui-inputs-core";

import { StyledFormGroup, StyledTypographyGroup } from "./styled";

export const Erc721: FC = () => {
  const form = useFormContext<any>();
  const moduleNative = useWatch({ name: "module.native" });
  const moduleErc20 = useWatch({ name: "module.erc20" });
  const moduleErc721 = useWatch({ name: "module.erc721" });

  useEffect(() => {
    if (!(moduleErc20 || moduleNative)) {
      form.setValue("module.erc721", false);
      form.setValue("erc721.contract", false);
      form.setValue("erc721.template", false);
      form.setValue("erc721.token", false);
      return;
    }

    form.setValue("erc721.contract", moduleErc721);
    form.setValue("erc721.template", moduleErc721);
    form.setValue("erc721.token", moduleErc721);
  }, [moduleNative, moduleErc20, moduleErc721]);

  return (
    <Grid>
      <SwitchInput name="module.erc721" />
      <StyledTypographyGroup>
        <FormattedMessage id="pages.calculator.erc721.requirements" />
      </StyledTypographyGroup>
      <StyledFormGroup>
        <CheckboxInput name="erc721.contract" disabled />
        <Typography>
          <FormattedMessage id="pages.calculator.erc721.contract" />
          <Link href="https://gemunion.github.io/admin/hierarchy/ERC721/contract/">more</Link>
        </Typography>
        <CheckboxInput name="erc721.template" disabled />
        <Typography>
          <FormattedMessage id="pages.calculator.erc721.template" />
          <Link href="https://gemunion.github.io/admin/hierarchy/ERC721/template/">more</Link>
        </Typography>
        <CheckboxInput name="erc721.token" disabled />
        <Typography>
          <FormattedMessage id="pages.calculator.erc721.token" />
          <Link href="https://gemunion.github.io/admin/hierarchy/ERC721/token/">more</Link>
        </Typography>
      </StyledFormGroup>
    </Grid>
  );
};
