import { FC, useState } from "react";
import { Grid } from "@mui/material";

import { PageHeader } from "@gemunion/mui-page-layout";
import { FormWrapper, AutoSave } from "@gemunion/mui-form";
import { ContactType } from "@framework/types";

import { CommonContactForm } from "../../../../components/forms/common-contact";
import { Erc20 } from "./erc20";
import { Erc721 } from "./erc721";
import { Erc998 } from "./erc998";
import { Erc1155 } from "./erc1155";
import { Other } from "./other";
import { MechanicsMarketing } from "./mechanics.marketing";
import { Native } from "./native";
import { Integrations } from "./integrations";
import { MechanicsGaming } from "./mechanics.gaming";
import { RequestQuotationText } from "./request-quotation";
import { Root, StyledGridContent } from "./styled";
import { empty } from "./empty";
import { Total } from "./total";
import { MechanicsGambling } from "./mechanics.gambling";
import { MechanicsMeta } from "./mechanics.meta";

export const CalculatorSelfHosted: FC = () => {
  const [initialValues, setInitialValues] = useState({ features: JSON.stringify(empty) });

  const handleSubmit = async (values: any) => {
    setInitialValues({ features: JSON.stringify(values) });

    return Promise.resolve();
  };

  return (
    <Root>
      <FormWrapper initialValues={empty} onSubmit={handleSubmit} showButtons={false} showPrompt={false}>
        <PageHeader message="pages.calculator.title">
          <Total />
        </PageHeader>
        <StyledGridContent container spacing={2}>
          <Grid item xs={12} md={6}>
            <Native />
            <Erc20 />
            <Erc721 />
            <Erc998 />
            <Erc1155 />
          </Grid>
          <Grid item xs={12} md={6}>
            <MechanicsMarketing />
            <MechanicsGaming />
            <MechanicsGambling />
            <MechanicsMeta />
            <Integrations />
            <Other />
          </Grid>
        </StyledGridContent>
        <AutoSave onSubmit={handleSubmit} />
      </FormWrapper>
      <CommonContactForm initialValues={initialValues} contactType={ContactType.GET_QUOTATION}>
        <RequestQuotationText />
      </CommonContactForm>
    </Root>
  );
};
