import { FC, useLayoutEffect, useState } from "react";
import { Box, Grid, Switch, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import type { INewsletterSubscription, INewsletterSubscriptionCreateDto, IUser } from "@framework/types";
import { NewsletterSubscriptionType } from "@framework/types";
import { ProgressOverlay } from "@gemunion/mui-page-layout";
import { useUser } from "@gemunion/provider-user";
import { useApiCall } from "@gemunion/react-hooks";
import type { IPaginationResult } from "@gemunion/types-collection";

import type { ITabPanelProps } from "../tabs";
import { Root, StyledNewsletter } from "./styled";

export const ProfileSubscriptions: FC<ITabPanelProps> = props => {
  const { open } = props;

  const user = useUser<IUser>();

  const [subscriptions, setSubscriptions] = useState<INewsletterSubscription[]>([]);

  const { fn: getSubscriptions, isLoading } = useApiCall(
    api => {
      return api
        .fetchJson({
          url: "/newsletter",
          data: {
            email: user.profile.email,
          },
        })
        .then((json: IPaginationResult<INewsletterSubscription>) => {
          if (json?.rows) {
            setSubscriptions(json.rows);
          }
        });
    },
    { success: false, error: false },
  );

  const { fn: subscribe, isLoading: isSubscribing } = useApiCall(
    (api, data: INewsletterSubscriptionCreateDto) => {
      return api
        .fetchJson({
          url: "/newsletter/subscribe",
          method: "POST",
          data,
        })
        .then(() => getSubscriptions());
    },
    { success: false, error: false },
  );

  const { fn: unsubscribe, isLoading: isRemoving } = useApiCall(
    (api, data: INewsletterSubscriptionCreateDto) => {
      return api
        .fetchJson({
          url: "/newsletter/unsubscribe",
          method: "POST",
          data,
        })
        .then(() => getSubscriptions());
    },
    { success: false, error: false },
  );

  const handleChange = (type: NewsletterSubscriptionType) => (_event: any, value: boolean) => {
    const data = { email: user.profile.email, type };
    return value ? subscribe(void 0, data) : unsubscribe(void 0, data);
  };

  useLayoutEffect(() => {
    if (user?.profile?.email) {
      void getSubscriptions();
    }
  }, [user?.profile]);

  if (!open) {
    return null;
  }

  return (
    <ProgressOverlay isLoading={isLoading || isSubscribing || isRemoving}>
      <Root container spacing={2}>
        {Object.values(NewsletterSubscriptionType).map(type => (
          <Grid item xs={12} key={type}>
            <StyledNewsletter>
              <Typography variant="h5">
                <FormattedMessage id={`enums.newsletterSubscriptionType.${type}`} />
              </Typography>

              <Switch
                checked={subscriptions.some(s => s.types?.includes(type as NewsletterSubscriptionType))}
                disabled={isLoading || isSubscribing || isRemoving}
                onChange={handleChange(type)}
              />
            </StyledNewsletter>
          </Grid>
        ))}
      </Root>
    </ProgressOverlay>
  );
};
