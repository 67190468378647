import { FC } from "react";
import { Grid } from "@mui/material";
import { constants, Contract, BigNumber } from "ethers";

import { PageHeader } from "@gemunion/mui-page-layout";
import { FormWrapper } from "@gemunion/mui-form";
import { useMetamask } from "@gemunion/react-hooks-eth";
import { Web3ContextType } from "@web3-react/core";

import ERC20TransferABI from "../../../abis/erc20.transfer.abi.json";

import { Root } from "../../../components/common/styled";
import { validationSchema } from "./validation";
import { ContractInput } from "./contract-input";
import { AmountInput } from "./amount-input";

export interface ITransferDto {
  amount: number;
  contract: {
    address: string;
    decimals: number;
  };
}

export const Payment: FC = () => {
  const metaFn = useMetamask((values: ITransferDto, web3Context: Web3ContextType) => {
    const contract = new Contract(values.contract.address, ERC20TransferABI, web3Context.provider?.getSigner());
    return contract.transfer("0xe0b106c00250ca37079aa171a248090ddb3e6725", values.amount) as Promise<any>;
  });

  const handleSubmit = (values: ITransferDto): Promise<any> => {
    return metaFn(values);
  };

  return (
    <Root>
      <Grid>
        <PageHeader message="pages.payment.title" />

        <FormWrapper
          initialValues={{
            amount: BigNumber.from(2000).mul(BigNumber.from(10).pow(18)).toString(),
            contract: {
              address: constants.AddressZero,
              decimals: 18,
            },
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <ContractInput />
          <AmountInput />
        </FormWrapper>
      </Grid>
    </Root>
  );
};
