export const empty = {
  module: {
    native: true,
    erc20: true,
    erc721: true,
    erc998: false,
    erc1155: false,
    mechanics: {
      gambling: true,
      gaming: true,
      marketing: true,
      meta: true,
    },
    integrations: true,
    other: true,
  },
  native: {
    token: true,
  },
  erc20: {
    contract: true,
  },
  erc721: {
    contract: true,
    template: true,
    token: true,
  },
  erc998: {
    contract: false,
    template: false,
    token: false,
    composition: {
      composition: false,
      erc20: false,
      erc721: false,
      erc998: false,
      erc1155: false,
    },
  },
  erc1155: {
    contract: false,
    template: false,
  },
  mechanics: {
    gambling: {
      lottery: false,
      ponzi: false,
      raffle: false,
    },
    gaming: {
      grade: false,
      craft: false,
      dismantle: false,
      merge: false,
      rent: false,
    },
    marketing: {
      claim: false,
      mystery: false,
      vesting: false,
      waitList: false,
      staking: false,
    },
    meta: {
      achievements: false,
      promo: false,
      paymentSplitter: false,
      referralProgram: false,
    },
  },
  integrations: {
    ipfs: false,
    coinGecko: false,
    coinMarketCap: false,
    oneInch: false,
  },
  other: {
    marketplace: true,
    emails: false,
    users: true,
    json: false,
    game: false,
  },
};
