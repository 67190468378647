import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { enqueueSnackbar } from "notistack";

import { NewsletterSubscriptionType } from "@framework/types";
import { FormWrapper } from "@gemunion/mui-form";
import { useApiCall } from "@gemunion/react-hooks";

import { TextInput } from "../../../inputs/text";
import { SubmitButton } from "../../../buttons";
import { StyledBold } from "../../styled";
import { validationSchema } from "./schema";
import { Root, StyledEmailWrapper, StyledForm, StyledHeader, StyledSubmitWrapper } from "./styled";

export const Subscribe: FC = () => {
  const { formatMessage } = useIntl();

  const { fn: subscribe } = useApiCall(
    (api, data: any) => {
      return api
        .fetchJson({
          url: "/newsletter/subscribe",
          method: "POST",
          data,
        })
        .then(() => {
          enqueueSnackbar(formatMessage({ id: "snackbar.subscriptionSuccess" }), { variant: "success" });
        });
    },
    { success: false, error: false },
  );

  const onSubmit = async (values: any, form: any) => {
    await subscribe(form, values);
  };

  return (
    <Root>
      <FormWrapper
        initialValues={{
          email: "",
          type: NewsletterSubscriptionType.MARKETING_NEWSLETTER,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        showButtons={false}
        showPrompt={false}
      >
        <StyledHeader>
          <FormattedMessage
            id="pages.landing.subscribe.title"
            values={{ b: chunks => <StyledBold>{chunks}</StyledBold> }}
          />
        </StyledHeader>
        <StyledForm>
          <StyledEmailWrapper>
            <TextInput name="email" label="" />
          </StyledEmailWrapper>
          <StyledSubmitWrapper>
            <SubmitButton />
          </StyledSubmitWrapper>
        </StyledForm>
      </FormWrapper>
    </Root>
  );
};
